import { createClient } from "@supabase/supabase-js";

// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://huprpremefnsrvgkdhqm.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imh1cHJwcmVtZWZuc3J2Z2tkaHFtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA1MTc0ODUsImV4cCI6MjAyNjA5MzQ4NX0.MEzX1NuBAti5S2aeD9ycP2lGydcZ3wlyF8M2f13L5vQ"
);

async function updatePassword(newPassword) {
  try {
    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      console.error("Error updating password:", error);
      return { error };
    }

    console.log("Password update successful:", data);
    return { data };
  } catch (error) {
    console.error("Unexpected error:", error);
    return { error };
  }
}

export { updatePassword };
