import logo from "./logo.svg";
import "./App.css";
import ResetPassword from "./Components/ResetPassword/ResetPassword";

function App() {
  return (
    <div className="App">
      <ResetPassword />
    </div>
  );
}

export default App;
