import React, { useState } from "react";
import "./ResetPassword.css";
import { ReactComponent as MySVG } from "../Assets/ic_logo_talkshare.svg";

import password_icon from "../Assets/password.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { updatePassword } from "../../services/AuthServices";
import CircularProgress from "@mui/material/CircularProgress";
const ResetPassword = () => {
  const [messageError, setMessageError] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const [onResult, setOnResult] = useState(false);
  const handleSubmit = async () => {
    if (newPassword === "" || repeatNewPassword === "") {
      setMessageError("Vui lòng điền mật khẩu mới");
      setErrorPassword(true);
      setOnLoading(false);
      return;
    }

    if (
      !validatePassword(newPassword) ||
      !validatePassword(repeatNewPassword)
    ) {
      setMessageError(
        "Mật khẩu phải chứa ít nhất một chữ hoa, ít nhất một chữ thường, ít nhất một chữ số, ít nhất một ký tự đặc biệt, dài ít nhất 8 ký tự. Vui lòng kiểm tra lại."
      );
      setErrorPassword(true);
      setOnLoading(false);
      return;
    }

    if (newPassword !== repeatNewPassword) {
      setMessageError("Mật khẩu không trùng khớp");
      setErrorPassword(true);
      setOnLoading(false);
      return;
    }

    setOnLoading(true);
    let result = await updatePassword(newPassword);

    if (result.data) {
      setErrorPassword(false);
      setOnResult(true);
      setNewPassword("");
      setRepeatNewPassword("");
    } else {
      setMessageError("Có lỗi xảy ra");
      setErrorPassword(true);
    }
    setOnLoading(false);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function validatePassword(password) {
    // Kiểm tra chữ in hoa
    const hasUppercase = /[A-Z]/.test(password);

    // Kiểm tra chữ thường
    const hasLowercase = /[a-z]/.test(password);

    // Kiểm tra chữ số
    const hasDigit = /\d/.test(password);

    // Kiểm tra kí tự đặc biệt
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
      password
    );

    // Kiểm tra độ dài tối thiểu 8 kí tự
    const hasMinLength = password.length >= 8;

    // Kiểm tra tất cả các điều kiện
    const isValid =
      hasUppercase &&
      hasLowercase &&
      hasDigit &&
      hasSpecialChar &&
      hasMinLength;

    return isValid;
  }

  return (
    <div className="container">
      <div className="header">
        <div className="logo-container">
          <div className="logo">
            <MySVG />
          </div>
          <div className="brand-name text">Talk Share</div>
        </div>
        <div className="text">Cập nhật mật khẩu</div>
        <div className="underline"></div>
      </div>
      <div className="inputs">
        <div className="input">
          <img src={password_icon} alt="" />
          <input
            type={showPassword ? "text" : "password"}
            name=""
            id=""
            value={newPassword}
            onChange={(e) => {
              setErrorPassword(false);
              setNewPassword(e.target.value);
            }}
            placeholder="Nhập mật khẩu mới"
          />
          {showPassword ? (
            <div
              className="icon"
              onClick={() => {
                handleShowPassword();
              }}
            >
              <FaEye size="24" />
            </div>
          ) : (
            <div
              className="icon"
              onClick={() => {
                handleShowPassword();
              }}
            >
              <FaEyeSlash size="24" />
            </div>
          )}
        </div>
        <div className="input">
          <img src={password_icon} alt="" />
          <input
            type={showPassword ? "text" : "password"}
            name=""
            id=""
            value={repeatNewPassword}
            placeholder="Nhập lại mật khẩu mới"
            onChange={(e) => {
              setErrorPassword(false);
              setRepeatNewPassword(e.target.value);
            }}
          />
          {showPassword ? (
            <div
              className="icon"
              onClick={() => {
                handleShowPassword();
              }}
            >
              <FaEye size="24" />
            </div>
          ) : (
            <div
              className="icon"
              onClick={() => {
                handleShowPassword();
              }}
            >
              <FaEyeSlash size="24" />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          errorPassword === true
            ? "error-container"
            : "error-container hiden-show-error"
        }
      >
        <div className="message-error">{messageError}</div>
      </div>
      <div className="submit-container">
        {onResult ? (
          <div className="success-container">
            <div className="success-message">Đổi mật khẩu thành công</div>
          </div>
        ) : onLoading ? (
          <CircularProgress />
        ) : (
          <div
            className="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Cập nhật mật khẩu
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
